.stickyHeaderContainer {
  grid-column: span 2;
  left: 0;
  padding-top: var(--spacing-2);
  padding-bottom: var(--spacing-15);
  right: 0;
  top: 0;
  transition: top 0.2s;
  z-index: 3;
}

.stickyHeader {
  composes: gridLargeWrapper from '@/global/style/Layout.module.css';
}

.sticky {
  position: sticky;
}

.shadow {
  box-shadow: var(--shadow-default);
}

.uspListContainer:not(:empty) {
  background-color: var(--color-hallon6);
  padding-bottom: var(--spacing-2);
  grid-column: span 2;
}

.uspList {
  composes: gridLargeWrapper from '@/global/style/Layout.module.css';

  list-style: none;
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--spacing-2);
  row-gap: var(--spacing-1);
  justify-content: center;
}

@media (min-width: 768px) {
  .stickyHeaderContainer {
    padding-bottom: var(--spacing-2);
  }

  .uspList {
    column-gap: var(--spacing-3);
    row-gap: var(--spacing-15);
  }

  .uspItem {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}
