.campaignContainer {
  display: grid;
  align-items: start;
  background-color: var(--color-hallon6);
  padding: var(--spacing-2);
  word-break: break-word;
}

.hasIcon {
  grid-template-columns: min-content auto;
  gap: var(--spacing-2);
}
